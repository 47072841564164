import React, { useContext, useEffect } from "react";
import { navigate, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { mainWhite, mainBlue, screen } from "../components/variables";
import { ImArrowLeft2 } from "react-icons/im";
import { StoreContext } from "../context";

const Wrapper = styled.div`
  padding: 70px 0 0 0;
  background: ${mainWhite};
  color: ${mainBlue};
  min-height: calc(60vh);
  @media ${screen.xsmall} {
    min-height: calc(100vh - 490px);
  }

  .hero {
    .heading {
      margin: 0 0 25px 0;
      line-height: 1.35;
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }

    p {
      font-size: 0.95rem;
      max-width: 490px;
      margin: 0 0 20px 0;

      &:last-child {
        margin: 0;
      }
    }

    .btn {
      border: 2px ${mainBlue} solid;
      color: ${mainBlue};
      text-transform: uppercase;
      margin: 30px 0 0 0;
      display: flex;
      align-items: center;
      position: relative;
      padding: 8px 0 4px 0;
      justify-content: center;
      font-size: 0.95rem;
      @media ${screen.xsmall} {
        padding: 8px 25px 4px 44px;
        font-size: 1rem;
        display: inline-block;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainBlue};
          color: ${mainWhite};
        }
      }

      svg {
        display: none;
        @media ${screen.xsmall} {
          display: block;
          font-size: 1rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 17px;
        }
      }
    }
  }
`;

const SubmittedPage = (props) => {
  const { clearQuote } = useContext(StoreContext);
  let heading;
  const isBrowser = typeof window !== "undefined";

  switch (props.location.state && props.location.state.form) {
    case "quote":
      heading = <h1 className="heading">Thank you for your request!</h1>;
      break;
    case "packaging":
      heading = <h1 className="heading">Thank you for your enquiry!</h1>;
      break;
    case "newsletter":
      heading = <h1 className="heading">Thank you for subscribing!</h1>;
      break;

    default:
      isBrowser && navigate("/404/");
  }

  useEffect(() => {
    if (props.location.state && props.location.state.form === "quote") {
      clearQuote();
    }
  }, []);

  return (
    <Layout rotatedSquareColour={mainWhite} isSubmittedPage>
      <Seo
        title={"Quote"}
        description={
          "In the meantime, we’ve answered some general inquiries about our products and services."
        }
      />

      <Wrapper>
        <header className="hero">
          <div className="inner-wrapper">
            {heading}
            <div>
              {props.location.state &&
                props.location.state.form !== "newsletter" && (
                  <p>
                    A representative from F-Pak Wholesalers will be in contact
                    with you to discuss your quote and assist you with
                    processing your order.
                  </p>
                )}

              <p>
                In the meantime, we’ve answered some general inquiries about our
                products and services.
              </p>
            </div>

            <Link className="btn" to="/faqs/">
              <ImArrowLeft2 />
              Frequently asked questions
            </Link>
          </div>
        </header>
      </Wrapper>
    </Layout>
  );
};

export default SubmittedPage;
